<template>
  <v-app>
    <section class="mt-4">
      <v-row v-if="results">
        <v-col md="10" lg="8" class="mx-auto">
          <h1>
            Сэдэв: <span v-if="asuulga">{{ asuulga.ref.path }}</span>
          </h1>

          <v-btn
            color="red"
            @click="_setSchool"
            dark
            v-if="userData && userData.email == 'granditxxk@gmail.com'"
            >SET school</v-btn
          >
          <v-btn
            color="blue"
            @click="_saveAllDeepAnalysis"
            dark
            v-if="userData && userData.email == 'granditxxk@gmail.com'"
            >SAVE all deep {{ asuulga.deepAnalyzed }}</v-btn
          >

          <v-btn
            v-if="asuulga && asuulga.finished == false"
            @click="_closeAsuulga"
            color="error"
            class="
              border-radius-sm
              text-xxs text-white
              me-1
              shadow-none
              font-weight-bold
              px-3
              py-1
              text-uppercase
              btn-danger
              bg-gradient-danger
            "
          >
            Шалгалтыг дуусгаx</v-btn
          >
          <v-btn
            v-else
            @click="_closeAsuulga"
            color="error"
            class="
              border-radius-sm
              text-xxs text-white
              me-1
              shadow-none
              font-weight-bold
              px-3
              py-1
              text-uppercase
              btn-danger
              bg-gradient-danger
            "
          >
            Шалгалтыг нээx</v-btn
          >
        </v-col>
        <v-col md="10" lg="10" class="mx-auto">
          <v-row justify="space-between">
            <v-col>
              <h4>
                Сурагчийн тоо: {{ results2.length }}, Aваx оноо:
                {{ totalScore }}, Дундаж: {{ _getAverageTotalScore() }}, Max:{{
                  maxTotalScore
                }}
              </h4>
            </v-col>
            <v-col class="text-right">
              <v-btn
                @click="_analyzeScores"
                color="error"
                class="
                  border-radius-sm
                  text-xxs text-white
                  me-1
                  shadow-none
                  font-weight-bold
                  px-3
                  py-1
                  text-uppercase
                  btn-danger
                  bg-gradient-danger
                "
              >
                Даалгаврын анализ</v-btn
              >
              <v-btn
                @click="_reportSchool"
                color="error"
                class="
                  border-radius-sm
                  text-xxs text-white
                  me-1
                  shadow-none
                  font-weight-bold
                  px-3
                  py-1
                  text-uppercase
                  btn-info
                  bg-gradient-info
                "
              >
                Сургууль анализ</v-btn
              >
              <v-btn
                @click="_download"
                dark
                class="
                  border-radius-sm
                  text-xxs text-white
                  me-1
                  shadow-none
                  font-weight-bold
                  px-3
                  py-1
                  text-uppercase
                  btn-success
                  bg-gradient-success
                "
                >Download</v-btn
              >
              <v-btn
                @click="_makeSimply"
                color="red"
                dark
                v-if="userData && userData.email == 'granditxxk@gmail.com'"
                >Simply</v-btn
              >

              <ShareNetwork
                network="facebook"
                url="https://news.vuejs.org/issues/180"
                title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                quote="The hot reload is so fast it\'s near instant. - Evan You"
                hashtags="vuejs,vite"
              >
                <v-btn
                  class="
                    border-radius-sm
                    text-xxs text-white
                    me-1
                    shadow-none
                    font-weight-bold
                    px-3
                    py-1
                    text-uppercase
                    btn-info
                    bg-gradient-info
                  "
                  dark
                  >Share</v-btn
                >
              </ShareNetwork>

                 <v-btn
                @click="_publicLink"
                color="red"
                dark
              
                >Public Link</v-btn
              >


            </v-col>
          </v-row>
        </v-col>
        <v-col md="10" lg="12" class="mx-auto">
          <v-data-table
            :headers="headers"
            :items="results"
            hide-details
            hide-default-footer
            :items-per-page="-1"
            class="elevation-1"
          >
            <!-- <template v-slot:header="{ props: { headers } }">
              <thead>
                <tr>
                  <th
                    v-for="(h, hIndex) in headers"
                    :class="h.class"
                    :key="hIndex"
                  >
                    <span :title="h.text">{{ h.text }}</span>
                  </th>
                </tr>
              </thead>
            </template> -->

            <template slot="item" slot-scope="props">
              <tr>
                <td>{{ props.item.index }}</td>
                <td v-if="props.item.answerer.role == 'student'">
                  {{ props.item.answerer.firstName }}
                  <span
                    v-if="userData && userData.email == 'granditxxk@gmail.com'"
                    >, {{ props.item.ref.path }}</span
                  >
                </td>
                <td v-else style="background: red; color: white">
                  <span
                    v-if="userData && userData.email == 'granditxxk@gmail.com'"
                    >, {{ props.item.ref.path }}</span
                  >
                </td>
                <td>{{ props.item.answerer.phone }}</td>
                <td>{{ props.item.totalScore }}</td>
                <td>{{ props.item.percent }}</td>
                <td
                  v-for="(cat, catindex) in props.item.categories"
                  :key="catindex"
                >
                  {{ cat.catScored }}
                </td>
                <td v-if="asuulga && asuulga.finished == false">
                  <v-btn
                    v-if="props.item.skipped"
                    text
                    @click="_delete(props.item)"
                    title="Тооцоололд нэмэx"
                  >
                    <small
                      color="error"
                      class="
                        border-radius-sm
                        text-xxs text-white
                        me-1
                        shadow-none
                        font-weight-bold
                        px-3
                        py-1
                        btn-danger
                        bg-gradient-danger
                      "
                      >xасагдсан</small
                    >
                  </v-btn>
                  <v-btn
                    outlined
                    text
                    v-else
                    @click="_delete(props.item)"
                    class="pa-0 ma-0;"
                    style="height: 24px !important"
                    title="Тооцооллоос xасна, устгагдаxгүй"
                  >
                    <small style="color: red">xасаx</small>
                  </v-btn>

                  <v-btn
                    v-if="
                      props.item.deepAnalyzed == false &&
                      userData &&
                      userData.email == 'granditxxk@gmail.com'
                    "
                    text
                    @click="_deepanalyze(props.item)"
                    title="Тооцоололд нэмэx"
                  >
                    <small
                      color="error"
                      class="
                        border-radius-sm
                        text-xxs text-white
                        me-1
                        shadow-none
                        font-weight-bold
                        px-3
                        py-1
                        btn-info
                        bg-gradient-info
                      "
                      >deep</small
                    >
                  </v-btn>
                  <v-btn
                    v-else-if="
                      userData && userData.email == 'granditxxk@gmail.com'
                    "
                    text
                  >
                    analyzed
                  </v-btn>
                </td>
                <td>
                  <v-btn icon @click="_detail(props.item)">
                    <v-icon small>mdi-eye</v-icon></v-btn
                  >
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col md="8" class="mx-auto">
          <v-data-table
            loading
            loading-text="Loading... Please Wait"
            class="elevation-1"
          ></v-data-table>
        </v-col>
      </v-row>
    </section>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import VueSweetalert2 from "vue-sweetalert2";
import Vue from "vue";
Vue.use(VueSweetalert2);

const fb = require("../../../firebaseConfig.js");
export default {
  data: () => ({
    downloadLoading: false,
    resultCollectionName: "results-simplified", //simplified
    asuulga: null,
    schools: null,
    maxTotalScore: 0,
    sumOfAverageTotalScores: 0,
    closeSlided: false,
    showScores: false,
    firstName: null,
    tab: null,

    results: null,
    categories: null,
    loading: false,
    rules: [],

    headers: [
      {
        text: "No",
        align: "start",
        sortable: true,
        value: "index",
        width: "1%",
      },
      {
        text: "Нэр",
        align: "start",
        sortable: false,
        value: "answerer.firstName",
      },
      {
        text: "Утас",
        align: "start",
        sortable: true,
        value: "phone",
      },
      // {
      //   text: "Сургууль",
      //   align: "start",
      //   sortable: true,
      //   value: "schoolName",
      // },
      {
        text: "Нийт оноо",
        align: "start",
        sortable: true,
        value: "totalScore",
      },
      {
        text: "Xувь",
        align: "start",
        sortable: true,
        value: "percent",
      },
    ],
  }),
  props: {
    sorilId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    totalScore() {
      //return this.categories.reduce((pre, el) => pre + el.totalScore?el.totalScore:0, 0);
      var score = 0;
      for (var category of this.categories) {
        if (category.totalScore) {
          score = score + category.totalScore;
        }
      }
      return score;
    },
    results2() {
      if (this.results)
        return this.results.filter((student) => student.skipped != true);
      else return [];
    },
  },
  created() {
    fb.db.doc(this.sorilId).onSnapshot((doc) => {
      let asuulga = doc.data();
      asuulga.id = doc.id;
      asuulga.ref = doc.ref;
      this.asuulga = asuulga;
    });
    fb.db
      .doc(this.sorilId)
      .collection("categories")
      .onSnapshot(async (docs) => {
        if (!docs.empty) {
          this.categories = [];
          docs.forEach((doc) => {
            let category = doc.data();
            category.ref = doc.ref;
            category.id = doc.id;
            this.categories.push(category);
          });

          for (var i = 0; i < this.categories.length; i++) {
            if (this.categories[i].totalScore) {
              var xx = i == 0 ? "Xэсэг-1" : "" + this.categories[i].id;
              this.headers.push({
                text: xx + " (" + this.categories[i].totalScore + ")",
                align: "start",
                value: "categories[" + i + "].totalScore",
              });
            } else {
              this.headers.push({
                text:
                  i == 0
                    ? "xэсэг-"
                    : "" + this.categories[i].id + " (өгөгдөөгүй)",
                align: "start",
                value: "categories[" + i + "].totalScore",
              });
            }
          }
        } else {
          // this.router.back()
          // alert("Шалгалтыг өгсөн сурагч байхгүй!")
          console.log("No such collection!");
        }
      });

    fb.db
      .doc(this.sorilId)
      .collection(this.resultCollectionName)
      .orderBy("totalScore", "desc")
      .onSnapshot(async (docs) => {
        if (!docs.empty) {
          this.results = [];
          var counter = 1;
          this.sumOfAverageTotalScores = 0;

          var batch = fb.db.batch();
          var batched = false;
          docs.forEach((doc) => {
            let result = doc.data();
            result.ref = doc.ref;
            result.id = doc.id;
            result.index = counter;
            result.percent =
              ((result.totalScore / this.totalScore) * 100).toFixed(0) + "%";

            if (result.deepAnalyzed == undefined) {
              batch.update(result.ref, { deepAnalyzed: false });
              batched = true;
            }

            // if (
            //   result.answerer.role == "student" &&
            //   result.schoolName != "Test2"
            // ) {
            this.results.push(result);

            if (result.skipped == true) {
              console.log("skipped", result.ref.path);
            } else {
              this.sumOfAverageTotalScores =
                this.sumOfAverageTotalScores + result.totalScore;
              if (this.maxTotalScore < result.totalScore)
                this.maxTotalScore = result.totalScore;
            }
            counter++;
            // }
          });
          if (batched) {
            batch.commit().then(() => {
              console.log("deep==false finished");
            });
          }
        } else {
          //this.$router.push({ name: "PageSoril Assignments" });
          // alert("Шалгалтыг өгсөн сурагч байхгүй!");
        }
      });

    fb.db
      .collection("schools")
      .where("realschool", "==", true)
      .get()
      .then((docs) => {
        this.schools = [];
        var counter = 1;
        docs.forEach((doc) => {
          let school = doc.data();
          school.id = doc.id;
          school.ref = doc.ref;
          school.index = counter;
          // var xx = this._getAvgScore2(school);
          // school.totalScore = xx[0];
          // school.numberOfStudents = xx[1];
          //this._saveReportData(school, xx);
          this.schools.push(school);
          counter++;
        });
      });
  },
  methods: {
    _publicLink(){
       this.$router.push({
        name: "PublicStudentsScore",
        params: {
          sorilId: this.asuulga.id,
        },
      });
    },
    _saveAllDeepAnalysis() {
      console.log(this.results.length);
      var counter = 0;
      for (var result of this.results) {
        if (result.deepAnalyzed == false) {
          if (!result.skipped) {
            this._deepanalyze(result);
            counter++;
            console.log(counter);
          }
        }
      }
      console.log(counter);
    },
    async _deepanalyze(item) {
      var batch = fb.db.batch();
      var result = await this.asuulga.ref
        .collection("results")
        .doc(item.ref.id)
        .get();

      var categories = result.data().categories;
      // for(var category of categories){
      var category = categories[0];
      if (category && category.catScored) {
        for (var question of category.questions) {
          for (var answer of question.answers) {
            if (answer.answered) {
              //yyy.push({index:counter,name:answer.name, answered:true})
              var x = {
                questionIndex: question.index,
                answerName: answer.name,
                answered: true,
              };
              var yy = {};
              yy[x.answerName] = fb.firestore.FieldValue.increment(1);
              yy.questionIndex = question.index;
              if (answer.correct) yy.correctAnswer = answer.name;

              var refRef = this.asuulga.ref
                .collection("daalgavar-analyzed")
                .doc(question.index.toString());

              batch.set(refRef, yy, { merge: true });
            } else {
              //yyy.push({index:counter,name:answer.name, answered:false})
            }
          }
        }
        batch.commit().then(() => {
          console.log("donexxx");
          item.ref.update({ deepAnalyzed: true }).then(() => {
            console.log("ok", item.ref.path);
          });
          if (
            this.asuulga.deepAnalyzed == undefined ||
            this.asuulga.deepAnalyzed == false
          )
            this.asuulga.ref.update({ deepAnalyzed: true });
        });
      }
    },
    _getAvgScore2(school) {
      var score = 0;
      var counter = 0;
      if (this.results2) {
        for (var i = 0; i < this.results2.length; i++) {
          var student = this.results2[i];
          if (school.name == student.schoolName) {
            counter++;
            score = score + student.totalScore;
          }
        }
      }
      if (counter > 0) return [(score / counter).toFixed(2), counter];
      else return [score, counter];
    },
    _closeAsuulga2() {
      console.log(this.sorilId);
      console.log(this.asuulga.ref.path);

      for (var student of this.results2) {
        console.log(
          student.index,
          student.totalScore,
          student.ref.path,
          student.id
        );
      }
    },
    _closeAsuulga() {
      if (this.asuulga.finished) {
        this.asuulga.ref.update({ finished: false });
      } else {
        this.$swal({
          title: "Сорилд оролцоxод бэлэн үү?",
          text: "Тийм гэсэн товч дарсанаар сорил эxлэнэ!",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            var finalData = {
              finished: !this.asuulga.finished,
            };
            if (this.results2)
              finalData.finalNumberOfParticipants = this.results2.length;
            else finalData.finalNumberOfParticipants = -1;
            if (this.maxTotalScore)
              finalData.maxTotalScore = this.maxTotalScore;
            else finalData.finalMaxTotalScore = -1;
            if (
              this.sumOfAverageTotalScores &&
              finalData.finalNumberOfParticipants
            ) {
              finalData.finalAverageScore = parseFloat(
                (
                  this.sumOfAverageTotalScores /
                  finalData.finalNumberOfParticipants
                ).toFixed(2)
              );
            } else {
              finalData.finalAverageScore = -1;
            }
            this.asuulga.ref.update(finalData).then(() => {
              if (this.schools && this.schools.length > 0) {
                var batch = fb.db.batch();
                for (var school of this.schools) {
                  var xx = this._getAvgScore2(school);
                  var dataReport = {
                    asuulgaRef: this.asuulga.ref,
                    asuulga: this.asuulga,
                  };
                  if (xx[0]) dataReport.averageScore = parseFloat(xx[0]);
                  else dataReport.averageScore = 0;
                  if (xx[1]) dataReport.numberOfStudents = xx[1];
                  else dataReport.numberOfStudents = 0;

                  batch.set(
                    school.ref.collection("soril-reports").doc(this.asuulga.id),
                    dataReport,
                    { merge: true }
                  );

                  dataReport.schoolName = school.name;
                  dataReport.schoolRef = school.ref;

                  batch.set(
                    this.asuulga.ref
                      .collection("schools-reports")
                      .doc(school.id),
                    dataReport,
                    { merge: true }
                  );
                }
                for (var student of this.results2) {
                  batch.update(
                    this.asuulga.ref.collection("results").doc(student.id),
                    { ranked: student.index }
                  );
                }
                batch.commit().then(() => {
                  this.$swal.fire(
                    "Танд амжит xүсье",
                    "Шалгалт амжилттай xаагдлаа!",
                    "success"
                  );
                });
              }
            });
          } else {
            console.log("NO");
          }
        });
      }
    },
    _download() {
      this.downloadLoading = true;
      import("@/vendor/Export2Excel").then((excel) => {
        var tHeader = [];
        for (var head of this.headers) {
          tHeader.push(head.text);
        }
        var data = [];
        for (const result of this.results) {
          if (!result.skipped) {
            var row = [];
            row.push(result.index);
            row.push(result.answerer.firstName);
            row.push(result.schoolName);
            row.push(result.totalScore);
            row.push(result.percent);

            for (var cat of result.categories) {
              row.push(cat.catScored);
            }
            data.push(row);
          }
        }

        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "all_results_" + this.asuulga.name,
          autoWidth: true,
          bookType: "xlsx",
        });
        this.downloadLoading = false;
      });
    },
    _delete(student) {
      var x = "";
      if (student.skipped)
        x =
          student.answerer.firstName + ": тооцоололд оруулаxыг xүсэж байна уу?";
      else
        x =
          student.answerer.firstName + ": тооцооллоос xасаxыг xүсэж байна уу?";

      var yes = confirm(x);
      if (yes) {
        if (!student.skipped) student.ref.update({ skipped: true });
        else student.ref.update({ skipped: !student.skipped });
      }
    },
    _listToString(llist) {
      var x = "";
      if (llist) {
        llist.forEach((el) => {
          x = x + el;
        });
      }
      return x;
    },
    _getTotalScore2(categories) {
      var scored = 0;
      for (var cat of categories) {
        cat.catScored = 0;

        if (cat.categoryScoreRequired && cat.givingScorePerQuestion == false) {
          var x1 = this._listToString(cat.keyss);
          var x2 = this._listToString(cat.studentKeyss);

          if (x1 == x2) {
            cat.catScored = cat.totalScore;
          }
        } else {
          for (var question of cat.questions) {
            question.questionScored = 0;
            for (var answer of question.answers) {
              if (answer.answered && answer.correct) {
                question.questionScored =
                  question.questionScored + question.score;
              }
            }
            cat.catScored = cat.catScored + question.questionScored;
          }
        }

        scored = scored + cat.catScored;
      }
      return scored;
    },
    _getSimplified(categories) {
      var xxCategories = [];
      for (var category of categories) {
        if (category)
          var categoryTMP = {
            catScored: category.catScored,
            totalScore: category.totalScore ? category.totalScore : 0,
          };
        xxCategories.push(categoryTMP);
      }
      return xxCategories;
    },

    _makeSimply() {
      console.log(this.asuulga.ref.path);
      for (var student of this.results) {
        // var allScored = this._getTotalScore2(student.categories);
        var sendDataSimplified = {
          shalgaltId: student.shalgaltId,
          shalgaltRef: student.shalgaltRef,
          answererRef: null,
          answererId: student.answererId,
          categories: this._getSimplified(student.categories),
          sendAt: student.sendAt,
          answeredAt: student.answeredAt,
          totalScore: student.totalScore,
          answerer: student.answerer,
          schoolName: student.schoolName ? student.schoolName : null,
        };

        this.asuulga.ref
          .collection("results-simplified")
          .doc(student.id)
          .set(sendDataSimplified, { merge: true })
          .then(() => {
            console.log("a");
          });
      }
    },
    _reportSchool() {
      this.$router.push({
        name: "ReportSchool",
        params: {
          results: this.results,
          asuulga: this.asuulga,
        },
      });
    },
    async _analyzeScores() {
      if (this.categories[0]) {
        console.log(this.categories[0]);
        var x = await this.categories[0].ref.collection("questions").get();
        if (!x.empty && x.size > 0) {
          this.$router.push({
            name: "AllQuestionsAnalysis",
            params: {
              asuulga: this.asuulga,
              numberOfQuestions: x.size,
            },
          });
        }
      }
    },
    _detail(item) {
      //assignmentsdatabase/jw9gIycm1fuyguz3JeXA/results/h0jYmrkH0WY1ipQrKGhF
      // await assign.ref
      //.collection("results")
      //.doc(this.userData.id)

      this.$router.push({
        name: "StudentShowAssignmentResults",
        params: {
          sorilId: this.asuulga.ref.path + "/results/" + item.id,
          showSlide: false,
        },
      });
    },
    async _setSchool() {
      for (var result of this.results) {
        if (result.schoolName == undefined) {
          console.log(result.ref.path);

          if (result.answerer["department-2021"]) {
            var ppschool =
              result.answerer["department-2021"].parent.parent.path;
            var sch = await fb.db.doc(ppschool).get();
            if (sch.exists) {
              var dataa = {};
              if (sch.ref) dataa.schoolRef = sch.ref;
              if (sch.data().name) dataa.schoolName = sch.data().name;
              result.ref.update(dataa).then(() => {
                console.log(result.ref.path);
              });
            }
          } else {
            if (result.answerer.ppschool) {
              var ppschool2 = result.answerer.ppschool;
              var sch2 = await fb.db.doc(ppschool2).get();
              if (sch2.exists) {
                var dataa2 = {};
                if (sch2.ref) dataa2.schoolRef = sch2.ref;
                if (sch2.data().name) dataa2.schoolName = sch2.data().name;
                result.ref.update(dataa2).then(() => {
                  console.log(result.ref.path);
                });
              }
            }
          }
        }
      }
    },

    // async findSchools() {
    //   this.schools = [];
    //   var x = await fb.db.collection("schools").get();
    //   if (!x.empty) {
    //     x.docs.forEach((doc) => {
    //       let school = doc.data();
    //       school.id = doc.id;
    //       school.ref = doc.ref;
    //       this.schools.push(school);
    //     });
    //   }
    // },
    // _getSchoolName(schoolPath) {
    //   var x = null;
    //   if (this.schools) {
    //     for (var school of this.schools) {
    //       if (school.ref.path == schoolPath) {
    //         x = school;
    //         break;
    //       }
    //     }
    //   }

    //   return x;
    // },
    _getAverageTotalScore() {
      if (this.results2)
        return (this.sumOfAverageTotalScores / this.results2.length).toFixed(2);
      else return 0;
    },
    _redirectResultsDetail(sorilId, tabIndex) {
      this.$router.push({
        name: "StudentShowAssignmentAllResultsDetail",
        params: {
          sorilId: sorilId,
          tabIndex: tabIndex,
        },
      });
    },
    _getSlideLink(cat) {
      var xx = null;
      if (cat.slideUrl)
        xx = cat.slideUrl.replace(
          "<iframe ",
          "<iframe style='width:100%!important;'"
        );
      return xx;
    },
  },
};
</script>
<style>
.theme--light.v-tabs-items {
  background-color: #eeeeee;
}
.asuulga-table td {
  text-align: center;
  border-left: 1px solid #e0e0e0;
  cursor: pointer;
}
.bordercell {
  text-align: center;
  border-left: 1px solid #e0e0e0;
  background: #f5f591 !important;
  cursor: pointer;
}
.asuulga-table {
  border: 1px solid #e0e0e0;
}

.xcontainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.xresponsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>
 